import React from "react";
import data from "../../../data/exam-schedule";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function ExamSchedulePage() {
  return (
    <Layout>
      <SEO
        title="Exam Schedule | Chinmaya Vidyalaya Vasant Vihar"
        description=""
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords="Chinmaya vidyalaya vasant vihar, chinmaya trust, chinmaya mission, online vidyalaya,online admission,schools in delhi,online registrations"
      />
      <div className="exam-schedule">
        <h1 className="heading">Exam-Schedule</h1>
        <br />

        <p>

        </p>
        <div className="exam-schedule__table">
          <div className="exam-schedule__table__row exam-schedule__table__row--heading">
            <div className="exam-schedule__table__col1">Exam</div>
            <div className="exam-schedule__table__col2">Date</div>
            <div className="exam-schedule__table__col3"></div>
          </div>
          {data.map((item, i) => (
            <div key={`row-${i}`} className="exam-schedule__table__row">
              <div className="exam-schedule__table__col1">{item.title}</div>
              <div className="exam-schedule__table__col2">
                {item.date}
              </div>
              <div className="exam-schedule__table__col3">
                <a href={item.link} download>
                  <span>Download</span> <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
