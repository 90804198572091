const examSchedule = [{
  title: "Periodic Test/Unit Test II Examination Class VI-VIII & class XI",
  date: "10/11/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/020%20-%20Periodic%20Test%20II%20for%20Classes%20VI%20to%20VII%20and%20XI.pdf"
},

{
  title: "Proficiency Examination Periodic Test II for classes IX, X & XII",
  date: "21/10/2022",
  link: "https://www.chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/017-PROFICIENCY-EXAMINATION-PERIODIC-TEST-II-for-Classes-IX-X-&-XII.pdf"
},
{
  title: "Date Sheet - Classes X & XII - Mid-Term Examination",
  date: "21/09/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASSES-X-&-XII-MID-TERM-EXAMINATION-2022.pdf",
},
{
  title: "Date Sheet - Class XI - Mid-Term Examination",
  date: "21/09/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASS-XI-MID-TERM-EXAMINATION.pdf",
},
{
  title: "Date Sheet - Classes VI to IX - Mid-Term Examination",
  date: "21/09/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/DATE-SHEET-CLASSES-VI-TO-IX-MID-TERM-EXAMINATION.pdf",
},
{
  title: "Half Yearly Examination Schedule for Class III - V (2022 - 2023)",
  date: "08/09/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-SCHEDULE-III-V(2022-23).pdf",
},
{
  title: "Half Yearly Examination Circular for Class I & II (2022 - 2023)",
  date: "08/09/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-CIRCULAR-I-&-II(2022-23).pdf",
},

{
  title: "Periodic I Schedule (2022-23) for classes III - V",
  date: "22/07/2022",
  link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/PERIODIC-I-SCHEDULE-2022-23-CLASSES-III-V.pdf",
}];

export default examSchedule;
